.about-title {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 16px;
  height: 8%;
  width: 100%;
  //background: green;
}

.about-body-wrapper {
  display: flex;
  justify-content: center;

}
.about-body {
  padding: 2rem;
  width: 50%;

  @media (max-width: 768px) {
    //padding: 1rem;
    width: 100%;
  }
}

.about-link-icons {
  display:flex;
  //align-items: center;
  //background: green;
  justify-content: center;
  //position: fixed;
  //right: 35px;
  //top: 30px;
}

