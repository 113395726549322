.message-center-wrapper {
  position: absolute;
  bottom: 5px;
  //width: 25%;
  padding: 1rem;

  //background: green;

  @media all and (max-width: 768px) {
    //width: 100%;
    left: 5px;
    right: 5px;
  }

  @media not all and (max-width: 768px) {
    left: 5px;
    width: 50%;
  }

  //border: solid;
  //background-color: green;
  // background-color: ${props => props.theme.color.grey[100]};
  // border-color: ${props => props.theme.color.grey[100]};
  // color: ${props => props.theme.color.grey[10]};
  //display: flex;
  //padding: 1rem;

  //border-width: 2px;
  //border-radius: 8px;

  // @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
  //   flex-direction: column;
  //   flex-wrap: nowrap;
  // }
}

.message {
  //position: fixed;
  //left: 35px;
  //bottom: 30px;

  border: solid;

  background-color: #555555;
  border-color: #555555;
  color: #CCCCCC;
  //display: flex;
  padding: 1rem;

  border-width: 2px;
  border-radius: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
