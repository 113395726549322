.cube-loading-error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  font-size: 12px;
  height: 100%;
}

.cube-loading-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  font-size: 26px;
  height: 100%;
}
