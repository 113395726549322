body {
    overflow: hidden;
    margin: 0;
    font-size: 16px;
    color: #333;

    background: #F1F3F3;

    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
}

//canvas {
//    outline: 0;
//    user-select: none;
//}

.cursor-pointer {
    cursor: pointer;
}

//#ribbon {
//    position: fixed;
//    right: 35px;
//    bottom: 30px;
//    //font-family: 'Open Sans', sans-serif;
//    user-select: none;
//    color: #555;
//    //&.disable {
//    //    color: #aaa;
//    //}
//}


#current {
    position: absolute;
    right: 35px;
    top: 50px;
    //font-family: 'Open Sans', sans-serif;
    user-select: none;
    //&.disable {
    //    color: #aaa;
    //}
}

//@media screen and (max-width: 768px) {
//    #ribbon {
//        left: 50%;
//        right: initial;
//        transform: translateX(-50%);
//    }
//}

.btn {
    display: inline-block;
    cursor: pointer;
    transition: 0.3s;
    padding: 15px 15px;
}

//#ribbon.disable .btn:hover{
//    text-shadow: none;
//}

.btn:hover {
    text-shadow: 0 0 4px rgba(0,0,0,0.3);
}

.btn:checked {
    background: rgba(0,0,0,0.1);
}
//
//#ribbon.disable .btn:checked{
//    text-shadow: none;
//}


